const AppFields = [
    { id: 'anniversary', label: 'Anniversary' },
    { id: 'business_name', label: 'Business Name' },
    { id: 'birthday', label: 'DOB' },
    { id: 'email', label: 'Email' },
    { id: 'full_name', label: 'Full Name' },
    { id: 'first_name', label: 'First Name' },
    { id: 'last_name', label: 'Last Name' },
    { id: 'phone', label: 'Phone' },
]
export {
    AppFields,
}